$(function(){

	$(".home_link").click(function()
	{
    $('html, body').animate({
        scrollTop: $("#menu_bar_section_placeholder").offset().top
    }, 500);
	});

	$(".services_link").click(function()
	{
    $('html, body').animate({
        scrollTop: $("#services_section").offset().top - 100
    }, 500);
	});

	$(".services_button").click(function()
	{
    $('html, body').animate({
        scrollTop: $("#services_section").offset().top - 100
    }, 500);
	});

	$(".about_us_link").click(function()
	{
    $('html, body').animate({
        scrollTop: $("#aboutus_section").offset().top - 150
    }, 500);
	});

	$(".aboutus_button").click(function()
	{
    $('html, body').animate({
        scrollTop: $("#aboutus_section").offset().top - 150
    }, 500);
	});

	$(".team_link").click(function()
	{
    $('html, body').animate({
        scrollTop: $("#team_container").offset().top - 150
    }, 500);
	});


	$(".contact_link").click(function()
	{
    $('html, body').animate({
        scrollTop: $("#contact_container").offset().top - 120
    }, 500);
	});

	$(".contact_button").click(function()
	{
    $('html, body').animate({
        scrollTop: $("#contact_container").offset().top - 120
    }, 500);
	});

	$('#photo_carousel').slick({
		slidesToShow: 1,
		autoplay: false,
		autoplaySpeed: 6000,
		prevArrow: false,
		nextArrow: false,
		dots: true,
		infinite: true,
		speed: 500,
		arrows: true});

	$('#photo_carousel').on('afterChange', function(event, slick, currentSlide, nextSlide)
	{
		if(currentSlide == 2)
		{
			$('#banner0_overlay').hide();
			$('#banner1_overlay').hide();
			$('#banner3_overlay').hide();

			$('#banner2_overlay').show();
		}

		if(currentSlide == 3)
		{
			$('#banner0_overlay').hide();
			$('#banner1_overlay').hide();
			$('#banner2_overlay').hide();

			$('#banner3_overlay').show();
		}

		if(currentSlide == 1)
		{
			$('#banner0_overlay').hide();
			$('#banner2_overlay').hide();
			$('#banner3_overlay').hide();

			$('#banner1_overlay').show();
		}

		if(currentSlide == 0)
		{
			$('#banner1_overlay').hide();
			$('#banner2_overlay').hide();
			$('#banner3_overlay').hide();

			$('#banner0_overlay').show();
		}
	});


	$('.tut_service_button').click(function()
	{
		$(this).siblings('.more_info_block').slideToggle();
	});


	$('#contact_submit_button').click(function()
  {
    $.ajax(
    {
      type: "POST",
      url: 'tut_mailer.php',
      data: {name: $('#contact_name').val(),
      			 last_name: $('#contact_last_name').val(),
             phone:$('#contact_phone').val(),
             date:$('#contact_date').val(),
             email:$('#contact_email').val(),
             message:$('#contact_message').val()},
      success: function(data)
      {
        alert(data);
      }
    });
  });

  $('.mobile_menu_icon_container').click(function(){

    $('html, body').animate({
        scrollTop: $("#menu_bar_section_placeholder").offset().top
    }, 500);

  	$('#mobile_menu_section').slideToggle();
  });

 $('#facilities_photos_container').magnificPopup({
  	delegate: 'a',
  	type: 'image',
  	tLoading: 'Loading image #%curr%...',
		gallery: {
			enabled: true,
			navigateByImgClick: true,
			preload: [0,1]
		}
	});

});
